import {TrackingEvent} from '../controller/Tracking';
import {TrackingType} from '../controller/Tracking';
import {TRACKING_EVENT} from '../controller/Tracking';


//--------------------------
// SETTINGS
//--------------------------
const MESSAGE_PREPEND = '<strong>BurgEins </strong>';
//--------------------------

export class FunFacts {
	constructor(api) {
		this.controller = api;
		
		this.$facts = null;
		this.lastFactId = -1;
		this.currentFactId = 0;
		
		this._initEventListeners();
		this._initMessageGenerator();
	}
	
	_initEventListeners() {
		let messageTrail = document.getElementById('whatWeDo');
		messageTrail.addEventListener('click', this.getSentence.bind(this));
	}
	
	_initMessageGenerator() {
		
		// get messages (as String) from the HTML script element
		const messages = document.querySelector('#messages').innerText;
		let domParser = new DOMParser();
		let list = domParser.parseFromString(messages, 'text/html');
		
		this.$facts = list.querySelectorAll('li');
		
		// introduce the all new scrollable page, until you've scrolled the first time
		this.getSentence(0);
	}
	
	getSentence(id) {
		
		if (id && id <= 1) {
			this.currentFactId = id;
		}
		else {
			while (this.lastFactId === this.currentFactId) {
				this.currentFactId = Math.round(Math.random() * (this.$facts.length - 3) + 2);
			}
		}
		this.lastFactId = this.currentFactId;
		
		const message = this.$facts[this.currentFactId];
		
		document.getElementById('whatWeDo').innerHTML = MESSAGE_PREPEND + message.innerHTML;
		
		const data = {
			name: TrackingEvent.CLICK,
			type: TrackingType.FACTS,
			v1: message.innerHTML,
			v2: ''
		};
		
		this.controller.trigger(TRACKING_EVENT, data);
	}
}

import { Slider } from '../view/Slider';

export class Agency {
	
	constructor(api, options, callback) {
		const config = {
			viewportHeight: 0.6
		};
		this.slider = new Slider('.agency.slider', api, config);
	}
}

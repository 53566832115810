import { Badges } from '../controller/Badges';
import { TrackingEvent } from '../controller/Tracking';
import { TrackingType } from '../controller/Tracking';
import { TRACKING_EVENT } from '../controller/Tracking';

import { environment } from '../utils/environment';

const SCROLL_DURATION = 1000;
const MAX_SPEED = 80; // in px/scrollBy

export class Navigation {
	
	constructor(api) {	// eslint-disable-line no-unused-vars
		
		this.controller = api;
		
		this.anchors = [];
		this.upButton = null;
		this.downButton = null;
		this.socialNavigation = null;
		
		this.currentIndex = 0;
		
		let isFF = environment().browserInfo().browser.toLowerCase() === 'firefox';
		this.scrollElement = isFF ? document.documentElement : document.body;
		
		this.collectAnchors();
	}
	
	
	collectAnchors() {
		this.anchors = [];
		
		let list = document.querySelectorAll('section, div.section');
		let bodyRect = document.body.getBoundingClientRect();
		
		for (let i = 0; i < list.length; i++) {
			if (list[i].getAttribute('data-target')) {
				this.anchors.push({
					name: list[i].getAttribute('data-target'),
					target: list[i],
					offsetTop: Math.round(list[i].getBoundingClientRect().top - bodyRect.top)
				});
			}
		}
	}
	
	detectCurrentAnchor() {
		let activeAnchor = null;
		
		this.anchors.forEach((anchor, index) => {
			if (anchor.target.getBoundingClientRect().top < (environment().innerHeight() * 0.1)) {
				activeAnchor = index;
			}
		});
		
		if (activeAnchor !== this.currentIndex && activeAnchor !== null) {
			
			this.currentIndex = activeAnchor;
			
			let data = {
				name: TrackingEvent.ENTER,
				type: TrackingType.SLIDE,
				target: this.anchors[activeAnchor].name
			};
			
			this.controller.trigger(TRACKING_EVENT, data);
			
			Badges.setBadge('scrollBadge', true);
			Badges.setBadge('leipzigBadge', true);
		}
		
		if (document.body.getBoundingClientRect().top < 0) {
			this.downButton.style.opacity = 0;
			this.downButton.style.pointerEvents = 'none';
		}
		
		if (this.currentIndex > 0) {
			this.socialNavigation.style.opacity = 1;
		}
		else {
			this.socialNavigation.style.opacity = 0;
		}
		
		if (this.currentIndex > 1) {
			this.upButton.style.opacity = 1;
			this.upButton.style.pointerEvents = 'auto';
		}
		else {
			this.upButton.style.opacity = 0;
			this.upButton.style.pointerEvents = 'none';
		}
	}
	
	showNavNextAndOfferMore() {
		if (document.body.getBoundingClientRect().top >= 0) {
			this.downButton.style.opacity = 1;
			this.downButton.style.pointerEvents = 'auto';
		}
	}
	
	scrollToSection(index) {
		
		const $section = this.anchors[index].target;
		
		function distanceToTop() {
			return $section.getBoundingClientRect().top;
		}
		
		function scrollTo() {
			
			let newTop = (originalTop < 0 ? Math.ceil : Math.floor)(scrollSpeed * Math.abs(distanceToTop()) / originalTop);
			
			window.scrollBy({
				top: newTop,
				left: 0
			});
			
			if (Math.abs(newTop) !== 0) {
				window.requestAnimationFrame(scrollTo.bind(this));
			}
			
		}
		
		const originalTop = distanceToTop();
		const scrollSpeed = Math.min(Math.abs(originalTop / 5), MAX_SPEED);
		
		if (Math.abs(originalTop) > 0) {
			window.requestAnimationFrame(scrollTo.bind(this));
		}
	}
	
	navigate(event) {
		
		event.preventDefault();
		
		this.collectAnchors();
		
		let direction = event.target.attributes.rel.value === 'prev' ? -1 : 1;
		let nextIndex = this.currentIndex + direction;
		
		if (nextIndex >= 0 && nextIndex < this.anchors.length) {
			
			this.scrollToSection(nextIndex);
			
			let data = {
				name: TrackingEvent.CLICK,
				type: TrackingType.NAVI,
				target: this.anchors[nextIndex].name,
				direction: direction === -1 ? 'previous' : 'next'
			};
			
			this.controller.trigger(TRACKING_EVENT, data);
		}
	}
	
	addEventHandler() {
		
		this.controller.subscribe('scroll', this.detectCurrentAnchor.bind(this));
		this.controller.subscribe('resize', this.collectAnchors.bind(this));
		this.controller.subscribe('factrequested', this.showNavNextAndOfferMore.bind(this));
		
		this.upButton.addEventListener('click', this.navigate.bind(this));
		this.downButton.addEventListener('click', this.navigate.bind(this));
		
		let externalLinks = document.querySelectorAll('a[rel="external"]');
		
		if (externalLinks.length <= 0) {
			return;
		}
		
		function track(event) {
			let data = {
				name: TrackingEvent.CLICK,
				type: TrackingType.LINK,
				target: this.href
			};
			this.controller.trigger(TRACKING_EVENT, data);
		}
		
		for (let i = 0; i < externalLinks.length; i++) {
			let link = externalLinks[i];
			link.addEventListener('click', track, true);
		}
	}
	
	activate() {
		
		this.upButton = document.querySelector('a[rel="prev"]');
		this.downButton = document.querySelector('a[rel="next"]');
		this.socialNavigation = document.querySelector('nav[id="social-navigation"]');
		if (!this.upButton || !this.downButton || !this.socialNavigation) {
			return;
		}
		this.addEventHandler();
		this.detectCurrentAnchor();
	};
	
	update() {
		this.collectAnchors();
	};
}

export var LazyLoad = (function load() {

	var version = '__version__';

	var exports = {};

	var lazyLoadStack = [];
	var lazyLoading = false;

	function lazyLoad() {
		if (lazyLoadStack.length === 0 || lazyLoading) {
			return;
		}

		var img = lazyLoadStack.shift();
		if (!img.loaded) {
			var imgLoader = new Image();
			imgLoader.onload = (event) => {
				lazyLoading = false;
				img.loaded = true;
				if (typeof img.callback === 'function') {
					img.callback(img);
				}
				lazyLoad();
			};
			lazyLoading = true;
			imgLoader.src = img.src;
		}
		else {
			lazyLoad();
		}
	}

	exports.add = (resource, callback) => {

		var image = resource;

		if (typeof resource === 'string' && resource.length > 0) {
			image = { src: resource, alt: '', loaded: false };
		}
		
		if (Array.isArray(resource)) {
			for (var i = 0; i < resource.length; i++) {
				exports.add(resource[i], callback);
			}
			return;
		}

		image.loaded = false;
		image.callback = callback;

		lazyLoadStack.push(image);
		lazyLoad();
	};

	(function constructor() {
	}());

	return exports;
	
}());

export var prefix = (function _() {
	var styles = window.getComputedStyle(document.documentElement, '');
	var pre = (Array.prototype.slice.call(styles).join('').match(/-(moz|webkit|ms)-/)
	|| (styles.OLink === '' && ['', 'o']))[1];

	var dom = ('WebKit|Moz|MS|O').match(new RegExp('(' + pre + ')', 'i'))[1];

	return {
		js: pre[0].toUpperCase() + pre.substr(1),
		dom: dom,
		css: '-' + pre + '-',
		lowercase: pre
	};
}());

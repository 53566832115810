import {Badges} from '../controller/Badges';
import {prefix} from '../utils/prefix';
import { environment } from '../utils/environment';

//--------------------------
// SETTINGS
//--------------------------
const PXL_DIAMETER = 26 * 2;
const USER_IMPACT = 10;
const OPACITY_GRID = 0.03;
const OPACITY_USER_CLICK = 0.3;
const MIN_COLOR = 0;
const MAX_COLOR = 255;
const PIXELS_CSS_SELECTOR = 'div.pixels';
const STOP_PLAYING_BELOW = 0;	// canvas.bounding.bottom
//--------------------------

/**
 *  pxlWalker & trblMaker
 *  @author mog
 */
export class Pixels {
	
	constructor(api, options, callback) {
		
		this.controller = api;
		
		this.ctx = null;
		this.canvas = null;
		this.playing = false;
		
		this.usrX = PXL_DIAMETER;
		this.usrY = PXL_DIAMETER;
		
		this.unicorn = false;
		this.unicornColor = null;
		
		this.fxUpdateTimeout = null;
		
		this.lastTime = 0;
		this.fTimer = 0;
		this.zeitScheibe = 0;
		
		this.isSticky = false;
		
		this._initEventListeners();
		this._initPixels();
		this._alignLogo();
	}
	
	togglePlay() {
		this.playing = !this.playing;
		this._updateFx();
	};
	
	_initEventListeners() {
		this.controller.subscribe('mousemove', this._getPos.bind(this));
		this.controller.subscribe('click', this._clickUserPos.bind(this));
		this.controller.subscribe('scroll', this._checkVisibility.bind(this));
		this.controller.subscribe('scroll', this._alignLogo.bind(this));
		this.controller.subscribe('resizecomplete', this._resizeWidth.bind(this));
		this.controller.subscribe('pageheightchange', this._resizeHeight.bind(this));
		this.controller.subscribe('pageheightchange', this._alignLogo.bind(this));
	}
	
	_initPixels() {
		this.ctx = this._createRenderContextAt(PIXELS_CSS_SELECTOR);
		this.canvas = this.ctx.canvas;
		
		this._resizeWidth();
		this._resizeHeight();
	}
	
	_alignLogo() {
		
		let logoWrapperTrail = document.querySelector('.pixels-message.trail');
		let logoWrapperFixed = document.querySelector('.pixels-message.fixed');
		let stickyTop = logoWrapperTrail.getBoundingClientRect().top;
		let floatingTop = logoWrapperTrail.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
		
		// signet: check whether we stop at the navigation bar
		if (stickyTop > 0) {
			
			if (!this.isSticky) {
				return;
			}
			
			this.isSticky = false;
			
			logoWrapperFixed.style.transition = 'none';
			logoWrapperFixed.style[prefix.js + 'transition'] = 'none';
			logoWrapperFixed.style.position = 'absolute';
			logoWrapperFixed.style.top = (floatingTop - logoWrapperTrail.getBoundingClientRect().top) + 'px';
			
			// then leave stick position
			setTimeout(() => {
				logoWrapperFixed.style.transition = 'all 0.2s linear';
				logoWrapperFixed.style[prefix.js + 'transition'] = 'all 0.2s linear';
				logoWrapperFixed.style.top = floatingTop + 'px';
				logoWrapperFixed.style.left = '5%';
				logoWrapperFixed.classList.remove('final');
			}, 50);
		}
		else {
			
			if (this.isSticky) {
				return;
			}
			
			this.isSticky = true;
			
			logoWrapperFixed.style.transition = 'none';
			logoWrapperFixed.style[prefix.js + 'transition'] = 'none';
			logoWrapperFixed.style.position = 'fixed';
			logoWrapperFixed.style.top = (stickyTop + logoWrapperFixed.getBoundingClientRect().height / 2) + 'px';
			
			// then move to stick position
			setTimeout(() => {
				logoWrapperFixed.style.transition = 'all 0.2s linear';
				logoWrapperFixed.style[prefix.js + 'transition'] = 'all 0.2s linear';
				logoWrapperFixed.style.top = '25px';
				logoWrapperFixed.style.left = '2%';
				logoWrapperFixed.classList.add('final');
			}, 50);
		}
	}
	
	_checkVisibility() {
		let shouldPlay = this.canvas.getBoundingClientRect().bottom > STOP_PLAYING_BELOW;
		
		if (shouldPlay !== this.playing) {
			this.togglePlay();
		}
	}
	
	_resizeHeight() {
		this.canvas.height = environment().innerHeight();
		this._checkVisibility();
	}
	
	_resizeWidth() {
		this.canvas.width = environment().innerWidth();
	}
	
	_rand(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
	
	_randColor() {
		return '#' + ((1 << 24) * Math.random() | 0).toString(16);
	}
	
	_randGreyColor() {
		let grey = this._rand(MIN_COLOR, MAX_COLOR).toString(16);
		grey = (grey.length > 1 ? grey : '0' + grey);
		return '#' + grey + '' + grey + '' + grey;
	}
	
	_getPos(e) {
		this.usrX = PXL_DIAMETER * Math.round((e.clientX - this.canvas.offsetLeft) / PXL_DIAMETER);
		this.usrY = PXL_DIAMETER * Math.round((e.clientY - this.canvas.offsetTop) / PXL_DIAMETER);
	}
	
	_setPixel(xPos, yPos) {
		if (this.unicorn) {
			this.ctx.fillStyle = this.unicornColor;
		}
		else {
			this.ctx.fillStyle = this._randGreyColor();
		}
		
		this.ctx.fillRect(xPos, yPos, PXL_DIAMETER, PXL_DIAMETER);
	}
	
	_calcFrameDelta() {
		
		let time = new Date();
		let timeInMs = time.getTime();
		
		if (this.lastTime === 0) {
			this.lastTime = timeInMs;
		}
		
		let interval = this.lastTime - this.fTimer;
		
		if (interval >= 1000) {
			this.fTimer = this.lastTime;
		}
		else {
			this.zeitScheibe = interval / 10;
		}
		
		this.lastTime = timeInMs;
	}
	
	_updateGrid() {
		
		let _width = window.innerWidth + PXL_DIAMETER;
		let _height = window.innerHeight + PXL_DIAMETER;
		
		for (let x = 0; x <= _width; x += PXL_DIAMETER) {
			for (let y = 0; y <= _height; y += PXL_DIAMETER) {
				this._setPixel(x, y);
			}
		}
	}
	
	_updateFx() {
		
		if (this.playing && this.ctx !== null) {
			
			this._calcFrameDelta();
			
			this.ctx.globalAlpha = OPACITY_GRID;
			this._updateGrid();
			
			clearTimeout(this.fxUpdateTimeout);
			this.fxUpdateTimeout = setTimeout(this._updateFx.bind(this), 50);
		}
	}
	
	_createRenderContextAt(path) {
		
		let container = document.querySelector(path);
		
		container.insertAdjacentHTML('afterbegin', '<canvas></canvas>');
		
		this.canvas = container.querySelector('canvas');
		
		this.canvas.width = container.offsetWidth;
		this.canvas.height = container.offsetHeight;
		
		return this.canvas.getContext('2d');
	}
	
	_plot4points(cx, cy, x, y) {
		
		this._setPixel(cx + x, cy + y);
		
		if (x !== 0) {
			this._setPixel(cx - x, cy + y);
		}
		if (y !== 0) {
			this._setPixel(cx + x, cy - y);
		}
		if (x !== 0 && y !== 0) {
			this._setPixel(cx - x, cy - y);
		}
	}
	
	_plot8points(cx, cy, x, y) {
		
		this._plot4points(cx, cy, x, y);
		if (x !== y) {
			this._plot4points(cx, cy, y, x);
		}
	}
	
	//circle with bresenheim algorithm
	//ported from http://en.wikipedia.org/wiki/Midpoint_circle_algorithm#Optimization
	_circle(cx, cy, radius) {
		
		let mRadius = radius * PXL_DIAMETER;
		
		let error = -mRadius;
		let x = mRadius;
		let y = 0;
		
		while (x >= y) {
			
			this._plot8points(cx, cy, x, y);
			
			error += y;
			y += PXL_DIAMETER;
			error += y;
			
			if (error >= 0) {
				x -= PXL_DIAMETER;
				error -= x;
				error -= x;
			}
		}
	}
	
	_clickUserPos() {
		if (!this.playing) {
			return;
		}
		
		this.unicorn = true;
		this.unicornColor = this._randColor();
		this.ctx.globalAlpha = OPACITY_USER_CLICK;
		
		
		for (let i = USER_IMPACT; i > 0; i--) {
			if (i * 10 < this.zeitScheibe) {
				
				this._circle(this.usrX, this.usrY, i);
			}
		}
		
		Badges.setBadge('fireworkBadge', true);
		
		this.unicorn = false;
	}
}

/**
 * This function encodes special characters, also including , / ? : @ & = + $ # ! ' ( ) *
 * @param {string} uri an URL
 * @returns {string} encoded URL
 */
export function strictEncode(uri) {
	return encodeURIComponent(uri).replace(/[!'()*]/g, function (c) {
		return '%' + c.charCodeAt(0).toString(16);
	});
}

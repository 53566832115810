import { Tracking, TRACKING_EVENT, TrackingEvent, TrackingType } from '../controller/Tracking';
import { Navigation } from '../controller/Navigation';

import { Agency } from '../view/Agency';
import { Team } from '../view/Team';
import { Portfolio } from '../view/Portfolio';
import { Pixels } from '../view/Pixels';
import { FunFacts } from '../view/FunFacts';
import { Blog } from '../view/Blog';

export class BaseAPI {
	
	constructor(config) {
		
		this.controller = {};
		this.model = {};
		this.view = {};
		
		this.tracking = new Tracking(this);
		
		this.controller.navigation = new Navigation(this);
		this.controller.navigation.update();
		this.controller.navigation.activate();
		
		this.config = config;
		this.debugEnabled = config['debug'] || false; // eslint-disable-line dot-notation
		
		// View elements of our root page
		if (window.location.pathname === '/') {
			this.view.pixels = new Pixels(this);
			this.view.funFacts = new FunFacts(this);
			this.view.news = new Blog(this);
			this.view.team = new Team(this);
			this.view.agency = new Agency(this);
			this.view.portfolio = new Portfolio(this);
		}
	}

	getVersion() {
		return '__version__';
	}

	
	subscribe(event, callback) {
		window.addEventListener(event, callback);
	}

	trigger(name, data) {
		let event = new CustomEvent(name, { detail: data });
		window.dispatchEvent(event);
	}
	
	
	scrollTop() {
		
		this.controller.navigation.scrollToSection(0);
		
		var data = {
			name: TrackingEvent.CLICK,
			type: TrackingType.NAVI,
			target: this.controller.navigation.anchors[0].name,
			direction: 'top'
		};
		
		this.trigger(TRACKING_EVENT, data);
	};
	
	contact() {
		
		window.location.href = '#contact';
		
		var data = {
			name: TrackingEvent.CLICK,
			type: TrackingType.NAVI,
			target: this.controller.navigation.anchors[(this.controller.navigation.anchors.length - 1)].name,
			direction: 'direct'
		};
		
		this.trigger(TRACKING_EVENT, data);
	};
	
	home() {
		
		var data = {
			name: TrackingEvent.CLICK,
			type: TrackingType.NAVI,
			target: 'home',
			direction: 'direct'
		};
		
		this.trigger(TRACKING_EVENT, data);
		
		window.location.href = '/';
	};
}

import { TrackingEvent } from '../controller/Tracking';
import { TrackingType } from '../controller/Tracking';
import { TRACKING_EVENT } from '../controller/Tracking';
import { prefix } from '../utils/prefix';
import { findRecursiveUp } from '../utils/find';

import { environment } from '../utils/environment';
import { visibility } from '../utils/visibility';

/** @type {Array<Instance>} */
let instances = [];

const brackets = `<div class="left-bracket">
				<div class="blank"></div>
				<div class="bracket">
					<div class="vertical-dotted-line"></div>
					<div class="horizontal-dotted-line"></div>
					<div class="circle">
						<div class="circle-outer"></div>
						<div class="circle-inner active"></div>
					</div>
				</div>
			</div>
			<div class="right-bracket">
				<div class="blank"></div>
				<div class="bracket">
					<div class="vertical-dotted-line"></div>
					<div class="horizontal-dotted-line"></div>
					<div class="circle">
						<div class="circle-outer"></div>
						<div class="circle-inner active"></div>
					</div>
				</div>
			</div>`;

const pagination = `<div class="slides-navigation">
			</div>`;

const indicator = `<div class="circle" data-slide-index="__id__">
				<div class="circle-outer"></div>
				<div class="circle-inner"></div>
			</div>`;

const dataSliderInstance = 'data-slider-instance';
const dataSlideIndicator = 'data-slide-index';
const dataSlideHeadline = 'data-slide-headline';

const MIN_MOVE_TO_SWIPE = 60;

export class Slider {
	
	/**
	 * Slider constructor
	 *
	 * @param  {?}         element   either a dom element or a css selector
	 * @param  {BEWeb}     api       page main script
	 *
	 * @return {Object}             [description]
	 */
	constructor(element, api, config) {
		
		this.controller = api;
		this.config = config;
		
		this.instance = null;
		
		this.bracketWidth = 0;
		this.closedXPosition = 0;
		
		this.startSwipeOffset = 0;
		
		this.sliderWrapper = null;
		this.slider = null;
		this.slides = [];
		
		this.currentSlide = 0;
		this.slideWidth = 0;
		
		this.headlineChanger = 0;	//timeout
		
		this.firstVisible = false;
		
		if (typeof element === 'number' || typeof element === 'undefined') {
			return instances[element || 0] ? instances[element || 0] : null;
		}
		
		if (element.nodeType) { // Is an element
			if (element.getAttribute(dataSliderInstance) !== null) { // Already a Slider instance
				return instances[element.getAttribute(dataSliderInstance)] || null;
			}
			
			if (!this.controller) {
				return null; // Can't initialize without the main API
			}
			
			return this.initialize(element);
		}
		
		if (typeof element === 'string' && element.length > 0) {
			let qs = element;
			if (qs.lastIndexOf('#', 0) === -1 && qs.lastIndexOf('.', 0) === -1) {
				qs += ', #' + qs + ', .' + qs;
			}
			
			let el = findRecursiveUp(qs)[0];
			return new Slider(el, api, config);
		}
	}
	
	initialize(gallery) {
		if (!gallery.getAttribute(dataSliderInstance)) { // Only bind once
			
			this.instance = {
				api:     this.controller,
				element: gallery,
				config: this.config
			};
			
			this.slider = gallery;
			this.slider.setAttribute(dataSliderInstance, instances.length);
			
			instances.push(this.instance);
			
			this.start();
		}
		
		return instances[parseInt(gallery.getAttribute(dataSliderInstance), 10)];
	}
	
	start() {
		this._initializeBrackets();
		this._initEventListeners();
		this._onresize();
		this._changePage(0);
	}
	
	_initializeBrackets() {
		
		this.sliderWrapper = this.slider.querySelector('.slides-wrapper');
		this.sliderWrapper.insertAdjacentHTML('beforeend', brackets);
		this.sliderWrapper.insertAdjacentHTML('afterend', pagination);
		
		this.slides = Array.prototype.slice.call(this.slider.querySelectorAll('.slide'));
		this.slideWidth = 100 / this.slides.length;
		for (let i = 0, len = this.slides.length; i < len; i++) {
			
			this.slider.querySelector('.slides-navigation')
				.insertAdjacentHTML('beforeend', indicator.replace(/__id__/gi, i));
			
			this.slides[i].style.width = this.slideWidth + '%';
		}
		this.slider.querySelector('.slide-panel').style.width = 100 * this.slides.length + '%';
	}
	
	_initEventListeners() {
		this.controller.subscribe('resize', this._onresize.bind(this));
		this.controller.subscribe('scroll', this._checkVisibility.bind(this));
		this.controller.subscribe('mouseover', this._checkVisibility.bind(this));
		
		let slideButtons = this.slider.querySelectorAll('.slides-navigation .circle');
		for (let i = 0; i < slideButtons.length; i++) {
			slideButtons[i].addEventListener('click', this.gotoSlide.bind(this), false);
		}
		
		this.slider.querySelector('.left-bracket').addEventListener('click', this.previousSlide.bind(this), false);
		this.slider.querySelector('.right-bracket').addEventListener('click', this.nextSlide.bind(this), false);
		
		this.slider.querySelector('.slides-window').addEventListener('touchstart', this.startSwipe.bind(this), true);
	}
	
	_onresize() {
		
		let totalWidth = this.slider.getBoundingClientRect().width;
		this.bracketWidth = this.slider.querySelector('.left-bracket').getBoundingClientRect().width;
		
		let availableWidth = (totalWidth - (2 * this.bracketWidth));
		if (window.matchMedia('(max-width: 480px)').matches) {
			availableWidth = totalWidth;
		}
		this.sliderWrapper.style.width = availableWidth + 'px';
		
		this.slider.querySelector('.left-bracket .blank').style.width = (this.sliderWrapper.getBoundingClientRect().width) + 'px';
		this.slider.querySelector('.right-bracket .blank').style.width = (this.sliderWrapper.getBoundingClientRect().width) + 'px';
		
		this.closedXPosition = (this.sliderWrapper.getBoundingClientRect().width / 2) - this.bracketWidth + 1;
		
		this._checkVisibility();
	}


	/**
	 * This method changes the pages of the current selected container box.
	 *
	 * @param {number} requestedPage new target slide
	 * @returns {void}
	 */
	_changePage(requestedPage) {

		let nextPage = parseInt(requestedPage, 10);

		if (nextPage < 0
			|| nextPage >= this.slides.length) {
			return;
		}

		let nextIndicator = this.slider.querySelector('.circle[' + dataSlideIndicator + '="' + nextPage + '"] .circle-inner');
		nextIndicator.classList.add('active');

		let nextHeadline = this.slider.querySelector('.slide:nth-child(' + (nextPage + 1) + ')').getAttribute(dataSlideHeadline);
		if (nextHeadline) {
			this.slider.querySelector('h1').style.opacity = 0;
			clearTimeout(this.headlineChanger);
			this.headlineChanger = setTimeout(() => {
				this.slider.querySelector('h1').innerText = nextHeadline;
				this.slider.querySelector('h1').style.opacity = 1;
			}, 500);
		}

		if (nextPage === this.currentSlide) {
			return;
		}

		let numberOfPages = nextPage - this.currentSlide;
		let currentTranslateX = this.currentSlide * this.slideWidth;

		let nextTranslateX = currentTranslateX + numberOfPages * this.slideWidth;
		
		this.slider.querySelector('.slide-panel').style['transform'] = 'translateX(' + '-' + nextTranslateX + '%)';
		this.slider.querySelector('.slide-panel').style[prefix.js + 'transform'] = 'translateX(' + '-' + nextTranslateX + '%)';

		let currentIndicator = this.slider.querySelector('.circle[' + dataSlideIndicator + '="' + this.currentSlide + '"] .circle-inner');
		currentIndicator.classList.remove('active');
		
		this.currentSlide = nextPage;
	}

	_checkVisibility() {

		let viewportHeight = environment().innerHeight() * this.config.viewportHeight;
		let childRect = this.sliderWrapper.getBoundingClientRect();

		if (childRect.height > viewportHeight) {

			// emulate a bigger screen (height) to make sure we reach the 100% visibility
			viewportHeight = childRect.height / this.config.viewportHeight;
		}

		let parentRect = {
			bottom: viewportHeight + (environment().innerHeight() - viewportHeight) / 2,
			height: viewportHeight,
			left: 0,
			right: environment().innerWidth(),
			top: (environment().innerHeight() - viewportHeight) / 2,
			width: environment().innerWidth()
		};

		let overlap = visibility().getOverlap(parentRect, childRect);

		if (overlap > 0 && !this.firstVisible) {
			this.config.firstVisibleCallback ? this.config.firstVisibleCallback() : () => {};
			this.firstVisible = true;
		}

		let xPos = this.closedXPosition - (overlap * this.closedXPosition) - (overlap * (this.bracketWidth + 1));

		this.slider.querySelector('.left-bracket').style.left = xPos + 'px';
		this.slider.querySelector('.right-bracket').style.right = xPos + 'px';

		this.slider.querySelector('.slides-navigation').style.opacity = overlap;

	}

	nextSlide() {
		this._changePage(this.currentSlide + 1);
	}

	previousSlide() {
		this._changePage(this.currentSlide - 1);
	}

	gotoSlide(event) {
		let slide = event.currentTarget.getAttribute(dataSlideIndicator);
		this._changePage(parseInt(slide, 10));
	}

	swipe(event) {
		let pointer = event.changedTouches ? event.changedTouches[0] : event;

		let currentDragOffset = pointer.clientX;
		let distance = this.startSwipeOffset - currentDragOffset;

		if (distance > MIN_MOVE_TO_SWIPE) {
			this.stopSwipe();
			this.nextSlide();
			return;
		}

		if (distance < (-1 * MIN_MOVE_TO_SWIPE)) {
			this.stopSwipe();
			this.previousSlide();
		}

	}

	resetStartSwipeValues(event) {
		let pointer = event.changedTouches ? event.changedTouches[0] : event;
		this.startSwipeOffset = pointer.clientX;
	}

	stopSwipe() {
		window.removeEventListener('touchmove', this.swipe.bind(this));
		window.removeEventListener('touchend', this.stopSwipe.bind(this));
	}

	startSwipe(event) {
		window.addEventListener('touchmove', this.swipe);
		window.addEventListener('touchend', this.stopSwipe);
		this.resetStartSwipeValues(event);
		return false;
	}
}

import { LazyLoad } from '../utils/LazyLoad';
import { Slider } from '../view/Slider';

const logoWrapper = '<ul class="slide"></ul>';
const LOGOS_PER_SLIDE = 6;

export function logoHolder(src) {
	return `<li><img alt="Kundenlogo" src="${src}"></li>`;
}

export class Portfolio {	// eslint-disable-line no-unused-vars
	
	constructor(api, options, callback) {
		
		this.controller = api;
		
		this.wall = null;
		this.config = {
			viewportHeight: 0.8,
			firstVisibleCallback: null
		};
		
		this.logosAdded = 0;
		
		if (document.querySelector('.homejs')
			&& document.querySelector('.references.slider')) {
			
			this.wall = document.querySelector('.references.slider');
			this._createWall();
			
			// just load images for the first wall
			LazyLoad.add(window['portfolioLogos'].slice(0, 6), this._appendLogo.bind(this));
			
			// others are loaded once we are on screen
			this.config.firstVisibleCallback = () => {
				LazyLoad.add(window['portfolioLogos'].slice(6), this._appendLogo.bind(this));
			};
			
			this.slider = new Slider(this.wall, this.controller, this.config);
		}
	}
	
	_createWall() {
		var slides = Math.ceil(window['portfolioLogos'].length / LOGOS_PER_SLIDE);
		for (var i = 0; i < slides; i++) {
			this.wall.querySelector('.slide-panel').insertAdjacentHTML('beforeend', logoWrapper);
		}
	}
	
	_appendLogo(imageObject) {
		
		this.logosAdded++;
		
		let wrapperIndex = Math.ceil(this.logosAdded / LOGOS_PER_SLIDE);
		let wrapper = this.wall.querySelector('ul:nth-of-type(' + wrapperIndex + ')');
		wrapper.insertAdjacentHTML('beforeend', logoHolder(imageObject.src));
	}
}

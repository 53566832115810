import { environment } from '../utils/environment';
import { visibility } from '../utils/visibility';

export class Blog {
	
	constructor(api, options, callback) {
		
		this.controller = api;
		
		this.bannerVisibility = 0;
		this.expanded = true;
		
		this.blogContainer = document.querySelector('.blog-container');
		this.teaser = null;
		if (this.blogContainer) {
			this.teaser = this.blogContainer.querySelector('.teaser');
		}
		this.blogIndexContainer = document.querySelectorAll('.blog-index__container');
		
		this._checkVisibility();
		this._initEventListeners();
	}
	
	_checkVisibility() {
		let defaultRequiredVisibility = 0.7;
		
		let viewportHeight = environment().innerHeight() * 0.8;
		const teaserHeight = this.teaser.getBoundingClientRect().height + 100;
		const childRect = document.querySelector('.blog').getBoundingClientRect();
		
		if (childRect.height > viewportHeight) {
			viewportHeight = childRect.height;
			defaultRequiredVisibility = 0.5;
		}
		
		const parentRect = {
			bottom: viewportHeight + (environment().innerHeight() - viewportHeight) / 2,
			height: viewportHeight,
			left: 0,
			right: environment().innerWidth(),
			top: (environment().innerHeight() - viewportHeight) / 2,
			width: environment().innerWidth()
		};
		
		this.bannerVisibility = visibility().getOverlap(parentRect, childRect);
		
		if (this.bannerVisibility > defaultRequiredVisibility) {
			this.blogContainer.style.height = teaserHeight + 'px';
			if (!this.expanded) {
				this._startInteractiveAssets();
			}
			this.expanded = true;
		}
		else {
			if (!this.expanded) {
				return;
			}
			
			this.blogContainer.style.height = (teaserHeight + 20) + 'px';
			setTimeout(() => {
				this.blogContainer.style.height = '0px';
				this._stopInteractiveAssets();
			}, 300);
			this.expanded = false;
		}
	}
	
	_initEventListeners() {
		this.controller.subscribe('resizecomplete', this._checkVisibility.bind(this));
		this.controller.subscribe('scroll', this._checkVisibility.bind(this));
	}
	
	_startInteractiveAssets() {
		if (this.blogContainer.querySelector('iframe[data-src]')) {
			this.blogContainer.querySelector('iframe[data-src]').src = this.blogContainer.querySelector('iframe[data-src]').getAttribute('data-src');
		}
	}
	
	_stopInteractiveAssets() {
		if (this.blogContainer.querySelector('iframe[data-src]')) {
			this.blogContainer.querySelector('iframe[data-src]').src = 'about:blank';
		}
	}
}

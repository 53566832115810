export var Badges = (function badges() {

	var version = '__version__';

	var exports = {};
	var syncTimer = 0;

	var storage = localStorage;
	var internalStorage = {
		'fireworkBadge': 'false',
		'scrollBadge': 'false',
		'leipzigBadge': 'false',
		'teamBadge': 'false'
	};

	function write() {
		Object.assign(storage, internalStorage);
	}

	function read() {
		for (var key in internalStorage) {
			if (storage.hasOwnProperty(key)) {
				internalStorage[key] = storage[key];
			}
		}
	}

	/**
	 * Rewrites the localStorage, in case someone tried to modify it from the outside.
	 * Of course, this is just preventing manipulations but not bullet proof ;-)
	 *
	 * @returns {void}
	 */
	function sync() {
		write();
	}

	function start() {
		read();
		write();

		syncTimer = setInterval(sync, 1000);
	}

	exports.getBadge = (item) => {
		return internalStorage[item] === 'true';
	};

	exports.setBadge = (item, value) => {
		internalStorage[item] = '' + value;
		write();
	};

	exports.destroy = () => {
		clearInterval(syncTimer);
	};

	(function constructor() {
		start();
	}());

	return exports;

}());

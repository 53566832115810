import {strictEncode} from '../utils/strictEncode';

export let TRACKING_EVENT = 'trackingEvent';

export let TrackingEvent = {
	ERROR: 'error',
	ENTER: 'enter',
	LEAVE: 'leave',
	CLICK: 'click',
	SCROLL: 'scroll',
	TIME: 'time',
	RESIZE: 'resize',
	PERFORMANCE: 'performance'
};

export let TrackingType = {
	PAGE: 'page',
	SLIDE: 'slide',
	NAVI: 'naviItem',
	BUTTON: 'naviButton',
	LINK: 'linkExternal',
	SCREEN: 'screen',
	FACTS: 'welcomeFact'
};

const TRK_BASE_PATH = '//stats.burgeins.de/?record=__RECORD__';

export class Tracker {
	constructor() {
		return {
			"version": "0.1",
			"event": {
				"name": "__EVENT__",
				"type": "info",
				"data": {
					"t": "__TIME_ON_PAGE__",
					"cb": "__CACHEBUSTER__"
				}
			},
			"app": {
				"name": "BEWeb",
				"version": "__version__",
				"environment": "__environment__",
				"data": {
					"domain": "__LOCATION__"
				}
			}
		};
	}
}


(function setNavigationStart() {
	if (!window.performance.timing) {
		try {
			return sessionStorage.setItem('navigationStart', Date.now());
		}
		catch (_error) {
			return null;
		}
	}
	return null;
}());

/**
 * @constructor
 *
 * @param {BaseAPI} api     [description]
 *
 * @returns {TrackingModel}
 */
export class Tracking {
	
	/**
	 * @param {BaseAPI} api publisher api
	 *
	 */
	constructor(_api) {
		this.model = {};
		this.startTime = Date.now();
		this.loadTime = window.performance.now();
		
		this.api = _api;
		
		this.addPageEventListener();
		this.triggerImpression();
		this.sendTimingResults();
	}

	trackEvent(data) {

		let evtData = Object.assign({}, data);
		delete evtData.name;

		let tracker = new Tracker();

		Object.assign(tracker["event"]["data"], evtData);

		let record = JSON.stringify(tracker)
			.replace(/__EVENT__/gi, data.name)
			.replace(/__LOCATION__/gi, strictEncode(location.href))
			.replace(/__TIME_ON_PAGE__/gi, (new Date()) - this.startTime)
			.replace(/__CACHEBUSTER__/gi, Math.round(Math.random() * 1000000));

		let trackUrl = TRK_BASE_PATH.replace(/__RECORD__/gi, record);
		(new Image()).src = trackUrl;
	}

	eventHandler(event) {

		let eventName = event.detail.name;
		let eventData = event.detail;

		switch (eventName) {

			case TrackingEvent.ENTER:
			case TrackingEvent.ERROR:
			case TrackingEvent.LEAVE:
			case TrackingEvent.CLICK:
			case TrackingEvent.TIME:
			case TrackingEvent.RESIZE:
			case TrackingEvent.PERFORMANCE:
				this.trackEvent(eventData);
				break;

			default:

				// unknown event, do nothing
				return;
		}
	}

	triggerImpression() {

		let data = {
			name: TrackingEvent.ENTER,
			type: TrackingType.PAGE,
			screen: screen.width + 'x' + screen.height + '@' + (window.devicePixelRatio || 1),
			window: window.innerWidth + 'x' + window.innerHeight
		};
		
		this.api.trigger(TRACKING_EVENT, data);
	}

	triggerLeavePage() {

		let data = {
			name: TrackingEvent.LEAVE,
			type: TrackingType.PAGE,
			duration: parseInt(((Date.now() - this.startTime) / 1000), 10),
			v2: ''
		};

		this.api.trigger(TRACKING_EVENT, data);
	}

	triggerScreenSize() {
		let data = {
			name: TrackingEvent.RESIZE,
			type: TrackingType.SCREEN,
			screen: screen.width + 'x' + screen.height + '@' + (window.devicePixelRatio || 1),
			window: window.innerWidth + 'x' + window.innerHeight
		};

		this.api.trigger(TRACKING_EVENT, data);
	}

	sendTimingResults() {
		let timing = {
			chromeFirstPaintTime: -1,
			crossBrowserLoadEvent: -1
		};

		return setTimeout(() => {
			if (performance) {
				if(performance.getEntriesByType('navigation').length > 0) {
				timing.crossBrowserLoadEvent = Math.ceil(performance.getEntriesByType("navigation").shift().duration);
				}
				if(performance.getEntriesByType('paint').length > 0) {
				timing.chromeFirstPaintTime = Math.ceil(performance.getEntriesByType("paint").shift().startTime);	// equals first-paint
				}
			}

			let data = {
				name: TrackingEvent.PERFORMANCE,
				type: TrackingType.PAGE,
				chromeFirstPaintTime: timing.chromeFirstPaintTime,
				crossBrowserLoadEvent: timing.crossBrowserLoadEvent
			};

			this.api.trigger(TRACKING_EVENT, data);
		}, 2000);
	}

	sendErrorEvent(event) {

		let message = event.message;
		let filename = event.filename;
		let lineno = event.lineno;
		let error = event.error;

		let context = {
			message: message,
			filename: filename,
			line: lineno,
			url: window.location.href,
			readyState: document.readyState,
			referrer: document.referrer,
			stack: error !== null ? error.stack : 0,
			historyState: JSON.stringify(window.history.state),
			timeSinceLoad: window.performance.now() - this.loadTime
		};

		let data = {
			name: TrackingEvent.ERROR,
			type: TrackingType.PAGE,
			log: JSON.stringify(context).replace(/#/gi, '')
		};

		this.api.trigger(TRACKING_EVENT, data);
	}

	addPageEventListener() {

		this.api.subscribe('beforeunload', this.triggerLeavePage.bind(this));
		this.api.subscribe('resizecomplete', this.triggerScreenSize.bind(this));
		this.api.subscribe('error', this.sendErrorEvent.bind(this));

		this.api.subscribe(TRACKING_EVENT, this.eventHandler.bind(this));
	}
}

export function visibility() {

	let self = {};

	/**
	 * Calculates the overlap between two rectangles
	 * @param {object} parent - ClientRect of the parent DOM element (most likely the window viewport)
	 * @param {object} child - ClientRect of the target DOM element
	 * @returns {number} Visible space of the child in percentage, between 0-1
	 */
	self.getOverlap = function(parent, child) {

		//self.getOverlapIllustration(parent, child, childId);

		let visibility = 0;
		let visibleRect = {
			top: (child.top > parent.top) ? child.top : parent.top,
			left: (child.left > parent.left) ? child.left : parent.left,
			bottom: (child.bottom < parent.bottom) ? child.bottom : parent.bottom,
			right: (child.right < parent.right) ? child.right : parent.right
		};
		visibleRect.width = visibleRect.right - visibleRect.left;
		visibleRect.height = visibleRect.bottom - visibleRect.top;

		let childSpace = child.width * child.height;
		let visibleSpace = visibleRect.width * visibleRect.height;

		if (visibleSpace > 0) {
			visibility = visibleSpace / childSpace;
		}

		return visibility;
	};

	/**
	 * Illustrates the overlap between two given rectangles
	 * * red border is the overlap
	 * * black dashed border is the bounding box for both rectangles
	 * @param {object} parent - ClientRect of the parent DOM element (most likely the window viewport)
	 * @param {object} child - ClientRect of the target DOM element
	 */
	self.getOverlapIllustration = function drawRects(parent, child, childId) {

		let topWin = beweb.utils.environment.topLevelWindow();
		let topDoc = topWin.document;

		let rects = topDoc.querySelectorAll('[id*="Rect"]');
		for ( let i = 0; i < rects.length; i++ ) {
			rects[i].parentElement.removeChild(rects[i]);
		}

		let bounding = {
			top: (child.top < parent.top) ? child.top : parent.top,
			left: (child.left < parent.left) ? child.left : parent.left,
			bottom: (child.bottom > parent.bottom) ? child.bottom : parent.bottom,
			right: (child.right > parent.right) ? child.right : parent.right
		};
		bounding.width = bounding.right - bounding.left;
		bounding.height = bounding.bottom - bounding.top;

		let visible = {
			top: (child.top > parent.top) ? child.top : parent.top,
			left: (child.left > parent.left) ? child.left : parent.left,
			bottom: (child.bottom < parent.bottom) ? child.bottom : parent.bottom,
			right: (child.right < parent.right) ? child.right : parent.right
		};
		visible.width = visible.right - visible.left;
		visible.height = visible.bottom - visible.top;

		let parentRect = topDoc.createElement('div');
		parentRect.id = 'parentRect';
		parentRect.style.cssText = 'pointer-events: none; background-color: #ff3e56; opacity: 0.3; position: fixed; width: ' + parent.width + 'px; height: ' + parent.height + 'px; left: ' + parent.left + 'px; top: ' + parent.top + 'px;';

		let childRect = topDoc.createElement('div');
		childRect.id = 'childRect-' + childId;
		childRect.style.cssText = 'pointer-events: none; background-color: #3eff56; opacity: 0.3; position: fixed; width: ' + child.width + 'px; height: ' + child.height + 'px; left: ' + child.left + 'px; top: ' + child.top + 'px;';
		let childLabel = topDoc.createElement('div');
		childLabel.id = 'childRect-' + childId + '-label';
		childLabel.innerText = childId + ': ' + self.getOverlap(parent, child, childId);
		childRect.appendChild(childLabel);

		let boundingRect = topDoc.createElement('div');
		boundingRect.id = 'boundingRect';
		boundingRect.style.cssText = 'pointer-events: none; border: 1px dashed #111111; position: fixed; width: ' + bounding.width + 'px; height: ' + bounding.height + 'px; left: ' + bounding.left + 'px; top: ' + bounding.top + 'px;';

		let visibleRect = topDoc.createElement('div');
		visibleRect.id = 'visibleRect';
		visibleRect.style.cssText = 'pointer-events: none; border: 1px solid #ff3e56; position: fixed; width: ' + visible.width + 'px; height: ' + visible.height + 'px; left: ' + visible.left + 'px; top: ' + visible.top + 'px;';

		topDoc.body.appendChild(parentRect);
		topDoc.body.appendChild(childRect);
		topDoc.body.appendChild(boundingRect);
		topDoc.body.appendChild(visibleRect);
	};

	return self;
}

const MIN_LANDSCAPE_WIDTH = 667;

export function environment() {
	
	let environment = {};
	
	function getOrientation() {
		if (environment.innerWidth() > environment.innerHeight() && environment.innerWidth() > MIN_LANDSCAPE_WIDTH) {
			return 1;	// landscape
		}
		
		return -1;	// portrait
	}
	
	environment.browserInfo = function() {
		let ua = navigator.userAgent;
		let tem;
		let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		let ret = {
			browser: null,
			version: 0
		};
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			
			ret.browser = 'MSIE';
			ret.version = parseInt((tem[1] || 0), 10);
			
			return ret;
		}
		
		if (M[1] === 'Chrome') {
			tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
			if (tem !== null) {
				ret.browser = tem.slice(1).join(' ').replace('OPR', 'Opera');
				
				return ret;
			}
		}
		
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		tem = ua.match(/version\/(\d+)/i);
		if (tem !== null) {
			M.splice(1, 1, tem[1]);
		}
		
		ret.browser = M[0];
		ret.version = parseInt(M[1], 10);
		
		return ret;
	};
	
	environment.innerWidth = function() {
		return window.innerWidth || (window.document.documentElement.clientWidth || window.document.body.clientWidth);
	};
	
	environment.innerHeight = function() {
		return window.innerHeight || (window.document.documentElement.clientHeight || window.document.body.clientHeight);
	};
	
	environment.orientation = function() {
		return getOrientation() === 1 ? 'landscape' : 'portrait';
	};
	
	environment.orientationInverse = function() {
		return getOrientation() === 1 ? 'portrait' : 'landscape';
	};
	
	/**
	 * Equals document.querySelector()
	 * @param {string} selector CSS Selector
	 * @return {HTMLElement}
	 */
	environment.get = function(selector) {
		return document.querySelector(selector);
	};
	
	/**
	 * Equals document.querySelectorAll()
	 * @param {string} selector CSS Selector
	 * @return {NodeList}
	 */
	environment.getAll = function(selector) {
		return document.querySelectorAll(selector);
	};
	
	/**
	 * Equals document.querySelectorAll()
	 * @param {string} selector CSS Selector
	 * @return {Array} List of HTMLElements
	 */
	environment.getAllAsArray = function(selector) {
		return Array.prototype.slice.call(document.querySelectorAll(selector));
	};
	
	/**
	 * Get the root window.
	 * @returns {Window}
	 */
	environment.topLevelWindow = function() {
		
		let ifr = window.frameElement;
		let win = window;
		
		while (ifr !== null) {
			win = ifr.ownerDocument.defaultView;
			ifr = win.frameElement;
		}
		
		return win;
	};
	
	
	/**
	 * Get the bounding client rectangle of an element, regardless whether the element is
	 * nested in iframes.
	 *
	 * @param {HTMLElement} element
	 * @returns {{bottom: Number, height: Number, left: Number, right: Number, top: Number,
	 *     width: Number}}
	 */
	environment.getTopBoundingClientRect = function(element) {
		
		let elementRect = element.getBoundingClientRect();
		
		let rect = {
			bottom: elementRect.bottom,
			height: elementRect.height,
			left: elementRect.left,
			right: elementRect.right,
			top: elementRect.top,
			width: elementRect.width
		};
		
		let win = element.ownerDocument.defaultView;
		let ifr = win.frameElement;
		
		while (ifr !== null) {
			
			let addRect = ifr.getBoundingClientRect();
			rect.left += addRect.left;
			rect.top += addRect.top;
			rect.right = rect.left + rect.width;
			rect.bottom = rect.top + rect.height;
			
			win = ifr.ownerDocument.defaultView;
			ifr = win.frameElement;
		}
		
		return rect;
	};
	
	environment.isIF = function() {
		return window !== top;
	};
	
	environment.isFIF = function() {
		return environment.isIF() && window.frameElement !== null;
	};
	
	return environment;
}

import { BaseAPI } from './api/BaseAPI';
import { onDocumentReady } from './utils/documentReady';
import { environment } from './utils/environment';

let api = null;

class BEWeb {
	
	constructor() {
		this.publisherAPI = null;
	}
	
	initialize(config, callbackFunction) {
		this.publisherAPI = new BaseAPI(config || {});
		this._fixChromeLetterStyling();
	}
	
	_fixChromeLetterStyling() {
		const browser = environment().browserInfo().browser.toLowerCase();
		
		if (browser !== 'chrome') {
			return;
		}
		
		const h2Elements = document.querySelectorAll('h2');
		for (let i = 0; i < h2Elements.length; i++) {
			h2Elements[i].classList.add('chrome-letter-styling');
		}
	}
	
	get api() {
		return this.publisherAPI;
	}
}

let beweb = null;

onDocumentReady(() => {
	beweb = new BEWeb();
	beweb.initialize();
	
	/* istanbul ignore else: There is no fallback object. */
	if (typeof window !== 'undefined') {
		window['BEW'] = beweb.api;
	}
});

export function onDocumentReady(callback, context) {
	let ctx = context || document;
	
	if (ctx.readyState === 'interactive' || ctx.readyState === 'complete') {
		callback.call();
	}
	else {
		let timeoutID = setTimeout(() => {
			timeoutID = 0;
			callback.call();
		}, 2000);

		ctx.onreadystatechange = () => {
			if (ctx.readyState === 'interactive') {
				if (timeoutID) {
					clearTimeout(timeoutID);
					callback.call();
				}
			}
		};
	}
}

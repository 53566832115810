function eachDocument(loopCallback) {
	var ifr = window.frameElement;
	var win = window;

	while (ifr) {
		var doc = ifr.ownerDocument;
		win = doc.defaultView;
		(loopCallback || function _() {})(doc);

		ifr = win.frameElement;
	}

	return win;
}

/**
 * search for an element based on the supplied css query
 *
 * @param  {string}   query  is a string containing one or more CSS selectors separated by commas.
 * @param  {Element=} ctx    [description]
 *
 * @return {Array<Element>}      [description]
 */
export function find(query, ctx) {
	var context = ctx || document;
	var q = query.split('/').join('\\/');
	return Array.prototype.map.call(context.querySelectorAll(q), function map(el) {
		return el;
	});
}

/**
 * search for an element based on the supplied css query
 * recursively going up all documents
 *
 * @param  {string}   query  is a string containing one or more CSS selectors separated by commas.
 * @param  {Element=} ctx    [description]
 *
 * @return {Array<Element>}      [description]
 */
export function findRecursiveUp(query) {
	var elements = find(query);
	eachDocument((ctx) => {
		elements = elements.concat(find(query, ctx));
	});

	return elements;
}

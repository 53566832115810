import { environment } from '../utils/environment';
import { visibility } from '../utils/visibility';

export class Team {
	
	constructor(api, options, callback) {
		this.teamWrapper = null;
		this.teamboxes = [];
		this.teamVisibility = 0;
		
		
		if(document.querySelector('section[data-target="_team"]')) {
			this.teamWrapper = document.querySelector('.team .centered');
			this.teamboxes = this.teamWrapper.querySelectorAll('.teambox');
			
			api.subscribe('scroll', this._update.bind(this));
			api.subscribe('pageheightchange', this._forceUpdate.bind(this));
			
			this._forceUpdate();
		}
	}

	_getVisibility(element) {

		let viewportHeight = environment().innerHeight() * 0.6;
		let childRect = element.getBoundingClientRect();

		if (this.teamWrapper.getBoundingClientRect().height > viewportHeight) {
			viewportHeight = environment().innerHeight();
		}

		let parentRect = {
			bottom: viewportHeight + (environment().innerHeight() - viewportHeight) / 2,
			height: viewportHeight,
			left: 0,
			right: environment().innerWidth(),
			top: (environment().innerHeight() - viewportHeight) / 2,
			width: environment().innerWidth()
		};

		return visibility().getOverlap(parentRect, childRect);
	}


	/**
	 * Adds a random number between 1-3 to each Knight.
	 * It will be used to calculate the maximum top (off) position
	 *
	 * @returns {void}
	 */
	_resetKnights() {
		let knights = document.querySelectorAll('.teambox .imagewrapper span');
		for (let i = 0; i < knights.length; i++) {
			knights[i].setAttribute('data-speed', '' + (1 + Math.random() * 2));
		}
	}

	/**
	 * @param {Boolean} force calculation, even in off-screen
	 *
	 * @returns {void}
	 */
	_moveKnights(force) {

		for (let t = 0; t < this.teamboxes.length; t++) {
			let visibility = this._getVisibility(this.teamboxes[t]);
			if (visibility === 0 && force !== true) {
				continue;
			}

			let boxHeight = this.teamboxes[t].querySelector('.imagewrapper').getBoundingClientRect().height;
			let knights = this.teamboxes[t].querySelectorAll('.imagewrapper span');
			for (let i = 0; i < knights.length; i++) {
				let distance = knights[i].getAttribute('data-speed') * boxHeight;
				let position = distance - Math.round(visibility * distance);
				knights[i].style.top = position < 0 ? '0px' : position + 'px';
			}
		}
	}

	_forceUpdate() {
		this._resetKnights();
		this._moveKnights(true);
	}

	_update() {

		let totalVisibility = this._getVisibility(this.teamWrapper);
		if (totalVisibility === this.teamVisibility) {
			return;
		}
		this.teamVisibility = totalVisibility;

		if (this.teamVisibility === 0) {
			this._forceUpdate();
			return;
		}

		this._moveKnights(false);
	}
}
